import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function App() {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ss.appstore.et/lastseen/users//Total-of-users');
        const { weeklyOnlineUsers } = await response.json();

        const labels = weeklyOnlineUsers.map(entry => formatTime(entry.date));
        const values = weeklyOnlineUsers.map(entry => entry.numberOfOnline);

        setChartData({
          labels,
          datasets: [
            {
              label: 'Number of Online Users',
              data: values,
              fill: false,
              borderColor: 'rgba(75,192,192,1)',
              tension: 0.1
            }
          ]
        });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chartRef.current && chartData.datasets) {
      const ctx = chartRef.current.getContext('2d');
      new Chart(ctx, {
        type: 'line',
        data: chartData,
        options: {
          // Add any chart options here
        }
      });
    }
  }, [chartData]);

  // Format the time in a readable format
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const options = { hour: 'numeric', minute: 'numeric' };
    return date.toLocaleString('en-US', options);
  };

  return (
    <div style={{ height: '100%' }}>
      <div style={{
        backgroundColor: 'white',
        minHeight: '150px',
        minWidth: '350px',
        height: '100%',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <h2 style={{
          marginTop: '20px',
          textAlign: 'center',
          fontSize: '20px',
        }}>Current Hour Number Of Online Users</h2>
        
        <center>
          <h4> </h4>
        </center>
   
      </div>
    </div>
  );
}

export default App;

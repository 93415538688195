// BarChart.js

import React, { useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "../../../../axios";

const BarChart = () => {
  const canvasRef = useRef(null);

  // lastseen/users/get--number-of-daily-users

  useEffect(() => {
    console.log("Test Graph");
    const getFriends = async () => {
      try {
        const res = await axios.get("users/get-number-of-weakly-users-data");
        // settotalUsers(res.data.totalUser);
        console.log("Test Graph"+res.data );
        console.log("Test Graph_22");
        // const lastSevenDaysData = filterLastSevenDays(res.data);
        const lastSevenDaysData = filterLastSevenDays(res.data.weaklyOnlineUsers);
        console.log("dateeeeeeee2" + res.data.length);

        console.log("dateeeeeeee" + lastSevenDaysData.length);
        // Aggregate data for weekly intervals
        const weeklyData = aggregateWeeklyData(lastSevenDaysData);
        console.log("number of online users++++++++++++++" + weeklyData.length);

        drawBarChart(lastSevenDaysData);

        console.log("total-users" + res.data.totalUser);
        console.log(res);
      } catch (err) {
        console.log("total-users" + err);
      }

      // try {
      //   const res = await axios.get("users/get-number-of-daily-users");
      //   setdaileOnlineUSers(res.data.todayOnlineUsers);

      //   console.log("total-daily-users" + res.data.todayOnlineUsers);
      //   console.log(res);
      // } catch (err) {
      //   console.log("total-users" + err);
      // }

      // try {
      //   const res = await axios.get("/users/get-number-of-weakly-users ");
      //   setweeklyonline2(res.data.weaklyOnlineUsers);

      //   console.log("total-users" + res.data.weaklyOnlineUsers);
      //   console.log(res);
      // } catch (err) {
      //   console.log("total-users" + err);
      // }
    };
    getFriends();
  }, []);

  // useEffect(() => {
  //   // const socket = io('http://localhost:8900'); // Replace with your Socket.IO server URL
  //   const socket = io("https://ss.appstore.et/");

  //   socket.emit("joinRoom", "Dashbord");

  //   socket.on("ListofonlineusersDataforBarGraph", (data) => {
  //     // Filter data for the last seven days
  //     const lastSevenDaysData = filterLastSevenDays(data.data);
  //     console.log("dateeeeeeee" + lastSevenDaysData.length);
  //     // Aggregate data for weekly intervals
  //     const weeklyData = aggregateWeeklyData(lastSevenDaysData);
  //     console.log("number of online users++++++++++++++" + weeklyData.length);

  //     drawBarChart(lastSevenDaysData);
  //   });

  //   // Clean up the socket connection when the component unmounts
  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // Function to filter data for the last seven days
  const filterLastSevenDays = (data) => {
    const currentDate = new Date();
    const sevenDaysAgo = currentDate.getTime() - 7 * 24 * 60 * 60 * 1000; // Seven days ago in milliseconds

    return data.filter((entry) => entry.date >= sevenDaysAgo);
    //return data;
  };

  // Function to aggregate data into weekly intervals
  const aggregateWeeklyData = (data) => {
    const weeklyData = {};

    data.forEach((entry) => {
      const date = entry.date;
      const weekStartDate = getWeekStartDate(date);

      if (!weeklyData[weekStartDate]) {
        weeklyData[weekStartDate] = { date: weekStartDate, numberOfOnline: 0 };
      }

      weeklyData[weekStartDate].numberOfOnline += entry.numberOfOnline;
    });

    return Object.values(weeklyData);
  };

  // Function to get the start date of the week for a given date
  const getWeekStartDate = (dateString) => {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const weekStartDate = new Date(date.setDate(diff));

    // Format the date to 'YYYY-MM-DD'
    const formattedStartDate = weekStartDate.toISOString().split("T")[0];

    return formattedStartDate;
  };

  // Function to draw the bar chart on the canvas with animation
  function getDayOfWeek(unixTimestamp) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(unixTimestamp);
    const dayIndex = date.getUTCDay();
    return daysOfWeek[dayIndex];
  }
  const drawBarChart = (data) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set up chart parameters
    const barWidth = 30;
    const barSpacing = 30;
    const startX = 50;
    const startY = canvas.height - 50;
    const maxValue = Math.max(...data.map((entry) => entry.numberOfOnline));

    // Draw y-axis labels
    ctx.fillStyle = "black";
    ctx.textAlign = "right";
    ctx.textBaseline = "middle";
    for (let i = 0; i <= 10; i++) {
      const label = Math.round((i / 10) * maxValue);
      const y = startY - (i / 10) * (canvas.height - 100);
      ctx.fillText(label, startX - 15, y);
    }

    // Draw x-axis labels
    ctx.textAlign = "center";
    ctx.textBaseline = "top";
    data.forEach((entry, index) => {
      const dayOfWeek = getDayOfWeek(entry.date);
      const x = startX + index * (barWidth + barSpacing) + barWidth / 2;
      ctx.fillText(dayOfWeek, x, startY + 10);
    });

    // Draw bars with animation and hover effect
    data.forEach((entry, index) => {
      const barHeight = (entry.numberOfOnline / maxValue) * (canvas.height - 100);
      const x = startX + index * (barWidth + barSpacing);
      const y = startY - barHeight;

      // ctx.fillStyle = 'rgba(75,192,192,0.6)';
      ctx.fillStyle = "rgba(24, 178, 247, 0.3)";
      // ctx.fillRect(x, canvas.height, barWidth, -barHeight);

      // Animation: Increase the bar height gradually
      const animationSteps = 30;
      let currentStep = 0;

      const animateBar = () => {
        if (currentStep <= animationSteps) {
          const animatedHeight = (currentStep / animationSteps) * barHeight;
          ctx.fillRect(x, startY - animatedHeight, barWidth, animatedHeight);
          currentStep++;

          // Schedule the next frame
          requestAnimationFrame(animateBar);
        }
      };

      // Hover effect: Display value when mouse is over the bar
      canvas.addEventListener("mousemove", (event) => {
        const mouseX = event.clientX - canvas.getBoundingClientRect().left;
        const mouseY = event.clientY - canvas.getBoundingClientRect().top;

        if (mouseX >= x && mouseX <= x + barWidth && mouseY >= y && mouseY <= startY) {
          // Mouse is over the bar, display value
          ctx.fillStyle = "black";
          ctx.fillText(entry.numberOfOnline, x + barWidth / 2, startY - barHeight - 10);
        }
      });

      // Start the animation
      animateBar();
    });

    // Draw axes
    ctx.beginPath();
    ctx.moveTo(startX - 10, startY);
    ctx.lineTo(startX - 10, 10);
    ctx.lineTo(canvas.width - 10, 10);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        minHeight: "150px", // Adjusted minimum height
        minWidth: "500px",
        height: "100%",
        padding: "10px",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h2
        style={{
          marginTop: "20px",
          textAlign: "center",
          fontSize: "20px",
        }}
      >
        Weekly Number Of Online Users
      </h2>
      <canvas
        ref={canvasRef}
        width={1000}
        height={400}
        style={{ border: "0px solid #000" }}
      ></canvas>
    </div>
  );
};

export default BarChart;

/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import CryptoJS from "crypto-js";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import  Spinner from "./Preloader";

import "./index.css"
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import SignIn from "layouts/authentication/sign-in";
function Tables() {
  const user = JSON.parse(localStorage.getItem('user'));

  if(user == null || ""){
    window.location.replace("/authentication/sign-up")
  }
  
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [DataPhone, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalInformation, setTotalInformation] = useState({});
  const [monthlyInformation, setmonthlyInformation] = useState({});
  const [weeklyInformation, setweeklyInformation] = useState({});
  const [currentInformation, setcurrentInformation] = useState([]);
  const [filterSelected, setfilterSelected] = useState([]);

  const options = ["Not Using", "With in week", "With in month"];
  const [updatedPhoneNumbers, setUpdatedPhoneNumbers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  const filteredInformation = currentInformation.filter((data) =>
    data.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setDropdownOpen(false);
  };
  const [count, setCount] = useState(() => {
    // Initialize count from localStorage or use 0 if not available
    const storedCount = localStorage.getItem("onlineUsersCount");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });
  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;
  useEffect(() => {
    // const socket = io("http://localhost:8900");
    const socket = io("https://ss.appstore.et/");

    // Fetch data initially
    socket.emit("joinRoom", "Dashbord");

    const handleTotalUsers = (datas) => {
      setTotalInformation(datas.data);
     
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      currentDate.setHours(0, 0, 0, 0);
      const pastDateWeek = new Date(
        currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
      );
      const pastDateMonth = new Date(
        currentDate.getTime() - 30 * 24 * 60 * 60 * 1000
      );
     

      const filteredDataWeek = datas.data.filter((entry) => {
        const entryDate = new Date(entry.updatedAt);
      
        return entryDate >= pastDateWeek && entryDate <= currentDate;
      });
      setweeklyInformation(filteredDataWeek);
    

      const filteredDataMonth = datas.data.filter((entry) => {
        const entryDate = new Date(entry.updatedAt);
       
        return entryDate >= pastDateMonth && entryDate <= currentDate;
      });
   

      setmonthlyInformation(filteredDataMonth);

      const uniquePhoneNumbers = new Set(
        filteredDataWeek.map((entry) => entry.phoneNumber)
      );
      const count = uniquePhoneNumbers.size;
    };

    socket.on("Totaluserdata", handleTotalUsers);
    updatePhoneNumbers();

    if (selectedOption === "Not Using") {
     
      const filteredPhoneNumbers = updatedPhoneNumbers.filter((phoneNumber) => {
        // Check if phoneNumber doesn't exist in totalInformation
        const ciphertext = CryptoJS.SHA256(
          phoneNumber == undefined
            ? phoneNumber
            : phoneNumber.replace(/\s/g, "")
        ).toString(CryptoJS.enc.Hex);
        phoneNumber = ciphertext.substring(0, 10);
        const phoneNumberExists = Object.values(totalInformation).some(
          (user) => user.phoneNumber === phoneNumber
        );

       
        return !phoneNumberExists;
      });

      // Log or process the filtered phone numbers
    
      setcurrentInformation(filteredPhoneNumbers);
      // If you want to display the filtered phone numbers, you can use them in your JSX
    }

    if (
      selectedOption === "With in week" &&
      typeof weeklyInformation === "object"
    ) {
      
      // Similar logic for week
      const filteredPhoneNumbers = updatedPhoneNumbers.filter((phoneNumber) => {
        const ciphertext = CryptoJS.SHA256(
          phoneNumber == undefined
            ? phoneNumber
            : phoneNumber.replace(/\s/g, "")
        ).toString(CryptoJS.enc.Hex);
        phoneNumber = ciphertext.substring(0, 10);
        const phoneNumberExists = Object.values(weeklyInformation).some(
          (user) => user.phoneNumber === phoneNumber
        );
        return !phoneNumberExists;
      });
    
      setcurrentInformation(filteredPhoneNumbers);
    }

    if (
      selectedOption === "With in month" &&
      typeof monthlyInformation === "object"
    ) {
      
      // Similar logic for month

      const filteredPhoneNumbers = updatedPhoneNumbers.filter((phoneNumber) => {
        const ciphertext = CryptoJS.SHA256(
          phoneNumber == undefined
            ? phoneNumber
            : phoneNumber.replace(/\s/g, "")
        ).toString(CryptoJS.enc.Hex);
        phoneNumber = ciphertext.substring(0, 10);

        const phoneNumberExists = Object.values(monthlyInformation).some(
          (user) => user.phoneNumber === phoneNumber
        );
        return !phoneNumberExists;
      });
     
      setcurrentInformation(filteredPhoneNumbers);
    }
  }, [selectedOption]);


  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
    useEffect(() => {
      const fetchToken = async () => {
 

          const apiUrl = 'https://accounts.insa.gov.et/admin/realms/INSA/users?enabled=true';
          const client_id = 'sirkuni_dashboard_client';
          const client_secret = 'MpCLmWcKbCtqovPIgqS2VSPXuJGv7p9Z';
          const grant_type = 'client_credentials';

        try {


          const tokenResponse = await fetch('https://accounts.insa.gov.et/realms/INSA/protocol/openid-connect/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              grant_type: grant_type,
              client_id: client_id,
              client_secret: client_secret,
            }),
          });
         

          if (!tokenResponse.ok) {
       
            throw new Error(`Failed to obtain access token: ${tokenResponse.statusText}`);
          }

        

          const { access_token, expires_in } = await tokenResponse.json();
         

        

          // Use the access token to make a request to the API
          fetchData(access_token);
        

          // Schedule token refresh every minute
          setTimeout(fetchToken, (expires_in - 60) * 1000);
        } catch (error) {
          setError(error.message);
        }
      };

      const fetchData = async (accessToken) => {
        const apiUrl = 'https://accounts.insa.gov.et/admin/realms/INSA/users?enabled=true';

        try {

          const apiResponse = await fetch(apiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (!apiResponse.ok) {
            throw new Error(`Failed to fetch data from the API: ${apiResponse.statusText}`);
          }

          const result = await apiResponse.json();
          setData(result);
        } catch (error) {
          setError(error.message);
        }
      };

      fetchToken();
    }, []);
  const updatePhoneNumbers = () => {
    const updatedNumbers = DataPhone.map((user) => {
      const phoneNumber = user.attributes?.phone;

      // Skip numbers that are undefined or null
      if (!phoneNumber) {
        return null;
      }

      // Convert phoneNumber to string
      const phoneNumberString = String(phoneNumber);

      // Remove leading "0" and add "+251" prefix if necessary
      let updatedNumber = phoneNumberString.replace(/^0/, "+251");

      // Check if the number already starts with "+251"
      if (!updatedNumber.startsWith("+251")) {
        updatedNumber = "+251" + updatedNumber;

        return updatedNumber;
      }

      return updatedNumber;
    }).filter((phoneNumber) => phoneNumber !== null);

    setUpdatedPhoneNumbers(updatedNumbers);
  };

  return (
    <div>{
      user?
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6"> Inactive Users</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <div className="phoneNumberTable">
                
          <ul
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
          >
              <span
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "center",
              // }}
              >
               
                <div className="dropdown-container">
                  <button
                    className="dropdown-toggle"
                    onClick={() => setDropdownOpen(!isDropdownOpen)}
                  >
                  <b className="insidetext">  {selectedOption || "Select an option"} </b>
                  </button>
                  {isDropdownOpen && (
                    <ul className="dropdown-menu-user">
                      {options.map((option, index) => (
                        <div
                          key={index}
                          onClick={() => handleOptionSelect(option)}
                        >
                          <p>{option}</p>
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              </span>
            <br/>
            <input
      style={{
        width: '300px',
        padding: '10px',
        border: '2px solid #3498db',
        borderRadius: '20px',
        outline: 'none',
        fontSize: '16px',
        transition: 'border-color 0.3s',
        marginBottom: "15px"
      }}
      type="text"

      value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
    placeholder="Search..."
    />
     {/* <p>hello 231</p> */}
          {  currentInformation.length >0 ?  <table style={{ borderCollapse: "collapse", borderRadius: "10px", width: "100%", minWidth: "400px" , border: "none"}}>
        <tbody>
          {  filteredInformation.map((data) => (
            <tr key={data} className="table-row">
              <td style={{ padding: "10px", border: "0.3px solid #ddd" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      backgroundColor: `${getRandomColor()}`,
                      marginRight: "10px",
                    }}
                  />
                  <p>{data}</p>
                </div>
              </td>
            </tr>
          ))}
        </tbody> 
      </table>:  <div><span>Please select option</span><Spinner/></div>}
          </ul>

          <div>
      {/* {DataPhone ? (
        <div>
          <h2>Data from API:</h2>
          <pre>{JSON.stringify(DataPhone, null, 2)}</pre>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {error && <p>Error test: {error}</p>} */}
    
    </div>
              </div>
            </SoftBox>
          </Card>
        </SoftBox>
       
      </SoftBox>
      <Footer />
    </DashboardLayout>
    :""
}</div>
  );
}

export default Tables;

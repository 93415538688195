import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
const ApiKey = "a325-71b0-227a-2012"
export const useSignup = () => {
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()

  const signup = async (email, password ,role,username) => {
    setIsLoading(true)
    setError(null)
    const response = await fetch('https://staging.appstore.et/api/user/signup',{
   
    // const response = await fetch('http://localhost:8300/api/user/signup', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'X-API-Key': ApiKey},
      body: JSON.stringify({ email, password,  role, username})
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      setError(json.error)
    }
    if (response.ok) {
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json))
      
      // update the auth context
      dispatch({type: 'LOGIN', payload: json})

      // update loading state
      setIsLoading(false)
    }
  }

  return { signup, isLoading, error }
}

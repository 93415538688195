/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import CryptoJS from "crypto-js";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Line } from "react-chartjs-2";
// import "chartjs-adapter-date-fns";
import { format } from "date-fns";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import "./index.css"
import App from "examples/Charts/LineCharts/GradientLineChart/linegraph";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import LineGraphAll from "./linegraph_all";
import SignIn from "layouts/authentication/sign-in";
function Tables() {


  const { columns, rows } = authorsTableData;
  const { columns: prCols, rows: prRows } = projectsTableData;

  const user = JSON.parse(localStorage.getItem('user'));

  if(user == null || ""){
    window.location.replace("/authentication/sign-up")
  }
  

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Number of Online Users",
        data: [],
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 2,
        fill: false,
      },
    ],
  });
  const [datas, setData] = useState({});
  const [weeklyonline, setweeklyonline] = useState(0);
  const [weeklyOnlineCount, setWeeklyOnlineCount] = useState(0);
  const [fromDate, setFromDate] = useState(0);
  const [toDate, setToDate] = useState(0);
  const [weeklyonline2, setweeklyonline2] = useState(0);
  useEffect(() => {
    // const socket = io("http://localhost:8900");
    const socket = io("https://ss.appstore.et/");

    

    // Fetch data initially
    socket.emit("joinRoom", "Dashbord");
    socket.emit("joinRoom", "room", { fromDate, toDate });
    const handleTotalUsers = (datas) => {
      setData(datas);
     
      // setweeklyonline2(count);
    };
    
    socket.on("Totaluserdata", handleTotalUsers);

    const handleTotalUsers2 = (data) => {
      if (data.data) {
        const labels = data.data.map((entry) =>
        format(new Date(entry.date), "MM/dd/yyyy")
      );
        const onlineUsers = data.data.map((entry) => entry.numberOfOnline);
        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: onlineUsers,
            },
          ],
        });
      } else {
        setChartData({
          labels: [],
          datasets: [
            {
              ...chartData.datasets[0],
              data: [],
            },
          ],
        });
      }
    };

    socket.on("ListofonlineusersDataforBarGraph2", handleTotalUsers2);

    const interval = setInterval(() => {}, 5 * 60 * 1000); // Refresh data every 5 minutes
    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, [fromDate, toDate]);





  
  const handleDateSubmit = (e) => {
    if(datas.data ==null || undefined || ""){
    
    }else{
    e.preventDefault();

    console.log("---------------");

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);

    console.log("current date==" + toDate);
    console.log("pastDate ==" + fromDate);

    const filteredData = datas.data.filter((entry) => {
      const entryDate = new Date(entry.updatedAt);
      console.log("entry" + entry.updatedAt);
      return entryDate >= new Date(fromDate) && entryDate <= new Date(toDate);
    });
  

   
    console.log("filteredData ==", filteredData.length);

    const uniquePhoneNumbers = new Set(
      filteredData.map((entry) => entry.phoneNumber)
    );
    const counts = uniquePhoneNumbers.size;
    setweeklyonline(datas.data.length);
    setweeklyonline2(counts);

    }
  };
  
  return (
    <div>
      {user ?
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Online Users With in The Interval</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
             <div>
      <ul
        style={{
          backgroundColor: "#f8f8f8",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor:"white",
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',

            }}
          >
            <form
              onSubmit={handleDateSubmit}
              style={{ marginBottom: "20px", display: "flex", flexDirection:"column" }}
            >
               <div  style={{ marginBottom: "20px", display: "flex", flexDirection:"row" }} >
              <div style={{ marginBottom: "10px" }}>
                <label style={{ marginBottom: "5px" }}>From:</label>
                <input
                  style={{
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  type="date"
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div>
                <label style={{ marginBottom: "5px" }}>To:</label>
                <input
                  style={{
                    padding: "10px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  type="date"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
              </div>
            <div>
              <button
                style={{
                  marginLeft: "45%",
                  backgroundColor: "#31B2F7",
                  width: "80px",
                  height: "40px",
                  color: "white",
                  padding: "10px",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",

                }}
              >
                Find
              </button>
              </div>
             

            </form>

            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "30px",
              }}
            >
              <h3>{weeklyonline2}</h3>
              <p> Online Users</p>
            </div>
          </span>
      </ul>

     
    </div>
  
            </SoftBox>
          </Card>
        </SoftBox>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Line Graph Analysis</SoftTypography>
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >


<div className="chart">
       <LineGraphAll/>
        {/* <Line data={chartData} /> */}
      </div>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  :""  
  }
    </div>
  );
}

export default Tables;

import React from 'react';
// import "./new.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import Signup from "./Signup";
import upload from "../assets/img/icons/upload.svg";
import { useSignup } from "../hooks/useSignup";
import "./sign.css";
const UserManagementForm = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [username, setIsUsername] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');


  const handleClick = () => {
    logout();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
    } else {
    setIsSubmitted(true);
    const userData = await signup(email, password, role,username);

    // Handle the user data as needed
    console.log(userData);
  }};
  return (
    <div>
      <div className="header">
        <div className="header-left active">
          <a href="index.html" className="logo">
            <img src="assets/img/logo.png" alt="" />
          </a>
          <a href="index.html" className="logo-small">
            <img src="assets/img/logo-small.png" alt="" />
          </a>
          <a id="toggle_btn" href="javascript:void(0);"></a>
        </div>

        <a id="mobile_btn" className="mobile_btn" href="#sidebar">
          <span className="bar-icon">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </a>

        <ul className="nav user-menu">
          {/* ... Your other menu items ... */}
        </ul>

        <div className="dropdown mobile-user-menu">
          <a href="javascript:void(0);" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-ellipsis-v"></i>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <a className="dropdown-item" href="profile.html">My Profile</a>
            <a className="dropdown-item" href="generalsettings.html">Settings</a>
            <a className="dropdown-item" href="signin.html">Logout</a>
          </div>
        </div>
      </div>
      {/* sidebar */}
      <section id="sidebar">
        <a href="#" className="brand">
          <i className="bx bxs-smile"></i>
          <span className="text"> Admin </span>
        </a>
        <ul className="side-menu top">
          <li className="active">
            <a href="#">
              <i className="bx bxs-dashboard"></i>
              <span className="text">Dashboard</span>
            </a>
          </li>

          <li>
            <a>
              <i className="bx bxs-message-dots"></i>
              <span className="text">
                <Link to="/list">User list</Link>
              </span>
            </a>
          </li>
          <li>
          <Link to="/upload">
            <a >
              <i className="bx bxs-group"></i>
               <span className="text">upload</span> 
            </a>
            </Link>
          </li>
        </ul>
        <ul className="side-menu">
          <li>
           <Link to='/download'>  <a href="#">
              <i className="bx bxs-cog"></i>
              <span className="text">download </span>
            </a>
            </Link> 
          </li>
          <li>
           <Link to='/listupload'>  <a href="#">
              <i className="bx bxs-cog"></i>
              <span className="text">list of upload </span>
            </a>
            </Link> 
          </li>
          <li>
            {user && (
              <div className="emailandlogout">
                <div className="Loginout"></div>
              </div>
            )}
            <a className="logout">
              <i className="bx bxs-log-out-circle"></i>
              <Link to="/">
                <a onClick={handleClick}>Log out</a>
              </Link>
            </a>
          </li>
        </ul>
      </section>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="page-title">
              <h4>User Management</h4>
              <h6>Add/Update User</h6>
            </div>
          </div>

          <div className="card" style={{ background: 'white' }}>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>User Name</label>
                      <input type="text" name="username" value={username} onChange={(e) => setIsUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input type="text" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="pass-group">
                        <input type="password" name="password" className="pass-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">

                    <div className="col-lg-3 col-sm-6 col-12">
                      <label>Role</label>
                      <select className="select" name="role" value={role} onChange={(e) => setRole(e.target.value)}>
                        <option>Select</option>
                        <option>admin</option>
                        <option>user</option>
                      </select>
                    </div>

                    <br />
                    <div className="form-group" style={{ marginTop: "12px" }}>
                      <label>Confirm Password</label>
                      <div className="pass-group">
                        <input type="password" name="confirmPassword" className="pass-inputs" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    
                    {error && <div className="error">{error}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {isSubmitted && !error && <div className="success">Account created successfully!</div>}

                  </div>

                  <div className="col-lg-12">

                    <button type="submit" className="btn btn-submit me-2" >Sign up
                    </button>
                    <button type="button" className="btn btn-cancel">
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default UserManagementForm;
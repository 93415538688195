// import React, { useState } from 'react';
// import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import { useAuthContext } from "hooks/useAuthContext";

// // pages & components
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import VirtualReality from "layouts/virtual-reality";
// import RTL from "layouts/rtl";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
// import UserManagementForm from "pages/AdminLogin";

// // Soft UI Dashboard React icons



// function AppMain() {
//   const { user } = useAuthContext();
//   const [name , setname]= useState("hio")
//   return (
//     <div >
//         <Routes>
//           <Route
//             path="/"
//             element={name ? <Dashboard /> : <SignIn />}
//           />
//           <Route
//             path="/authentication/sign-in"
//             element={!name ? <SignIn /> : <Dashboard /> }
//           />
//           <Route
//             path="/analytics"
//             element={name ? <Tables /> : <SignIn />}
//           />
//           <Route
//             path="/userManagment"
//             element={name ? <Billing /> : <SignIn />}
//           />
//           {/* Add other routes as needed */}
//         </Routes>
//     </div>
//   );
// }

// export default AppMain;

import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

import "./fileform.css"

const BarChart = () => {
  let user = JSON.parse(localStorage.getItem('user'));

  // Function to draw the bar chart on the canvas with animation
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [item, setItem] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append('profile', file);
    formData.append('name', name);
    formData.append('item', item);
    formData.append('description', description);
    formData.append('uploader', user.email);

    fetch('https://staging.appstore.et/api/upload', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLoading(false);

        // Handle the response data as needed
        setFile(null);
        setName('');
        setItem('');
        setDescription('');
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        // Handle any errors
      });
  };

  const [ipAddresses, setIpAddresses] = useState([]);

  useEffect(() => {
    const fetchIpAddresses = async () => {
      try {
        const response = await fetch('/api/profile/ip');
        const data = await response.json();
        setIpAddresses(data.ipAddresses);
      } catch (error) {
        console.error(error);
      }
    };

    fetchIpAddresses();
  }, []);
  return (
    <div>
          <div
      style={{
        backgroundColor: 'white',
        minHeight: '150px', // Adjusted minimum height
        minWidth: '500px',
        // height: '100%',
        margin:"20px",
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <form onSubmit={handleSubmit} className="profile-upload-form">
        <div className="form-group">
          <label htmlFor="file">Setup:</label>
          <input type="file" id="file" onChange={handleFileChange} />
        </div>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="item">Item:</label>
          <input type="text" id="item" value={item} onChange={(e) => setItem(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea id="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
        </div>
        <div className="form-group">
          {loading ? (
            <button type="button" className="submit-button loading" style={{ marginLeft: "130px" }} disabled>
              <div className="spinner"></div>
              Uploading...
            </button>
          ) : (
            <button type="submit" className="submit-button" style={{ marginLeft: "130px" }}>
              Upload
            </button>
          )}
        </div>
      </form>
      </div>

      <div
      style={{
        backgroundColor: 'white',
        minHeight: '150px', // Adjusted minimum height
        minWidth: '500px',
       
        margin:"20px",
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div>
      <h2> Downloader IP Addresses</h2>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>IP Address</th>
          </tr>
        </thead>
        <tbody>
          {ipAddresses.map((ip, index) => (
            <tr key={index}>
              <td style={{ padding: '8px', textAlign: 'left', borderBottom: '1px solid #ddd' }}>{ip}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
    </div>
    
  );
};

export default BarChart;


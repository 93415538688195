import { useState } from "react";
import { useSignup } from "../hooks/useSignup";
import "./sign.css";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitted(true);
    const userData = await signup(email, password, role);

    // Handle the user data as needed
    console.log(userData);
  };
  return (
    <form className="signup" onSubmit={handleSubmit}>
      <h3>Sign Up</h3>

      <label>Username:</label>
      <input
        type="text"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <label>Password:</label>
      <input
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="">Status</option>
        <option value="user">Admin</option>
        <option value="admin">User</option>
      </select>

      <button disabled={isLoading}>{isSubmitted ? "Signing up..." : "Sign up"}</button>
      {error && <div className="error">{error}</div>}
      {isSubmitted && !error && <div className="success">Account created successfully!</div>}
    </form>
  );
};

export default Signup;
/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart/index1";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Soft UI Dashboard React base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import BuildByDevelopers from "layouts/dashboard/components/BuildByDevelopers/index1";

import WorkWithTheRockets from "layouts/dashboard/components/WorkWithTheRockets/index1";
import Projects from "layouts/dashboard/components/Projects";
import OrderOverview from "layouts/dashboard/components/OrderOverview";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
//socket

import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { Link } from 'react-router-dom'
import LineGraph from "examples/Charts/LineCharts/GradientLineChart/linegraph1"
import "./index.css"
import Spinner from "./Preloader"
import { useAuthContext } from "hooks/useAuthContext";
import SignIn from "layouts/authentication/sign-in";

function Adminpage() {
  const { size } = typography;
  const { chart, items } = reportsBarChartData;
/////////////////////////////////////////////////////////
const [isLoading, setIsLoading] = useState(true);

const [datas, setData] = useState({});
const [weeklyonline, setweeklyonline] = useState(0);
const [weeklyonline2, setweeklyonline2] = useState(0.5);
const [daileOnlineUSers, setdaileOnlineUSers] = useState(0.5);

const [searchDate, setSearchDate] = useState("");
const barChartRef = useRef(null);
const lineChartRef = useRef(null);
const [counter, setCounter] = useState(0.5);
const [datasRefresh, setdatasRefresh] = useState({});
const [intervals, setinterval] = useState({});
const [testuser, setTestUser] = useState({});
const [countapp, setCountapp] = useState(0.5);
const chartRef = (useRef < HTMLCanvasElement) | (null > null);
const [onlineDataChanged, setOnlineDataChanged] = useState(false);
const [count, setCount] = useState(() => {
  // Initialize count from localStorage or use 0 if not available
  const storedCount = localStorage.getItem("onlineUsersCount");
  return storedCount ? parseInt(storedCount, 10) : 0;
});
// const { user } = useAuthContext();
// useEffect(() => {
//   setTestUser(user);
//   if(testuser == null || ""){
//     window.location.replace("/authentication/sign-up")
//   }
// }, [testuser]);
const user = JSON.parse(localStorage.getItem('user'));

if(user == null || ""){
  window.location.replace("/authentication/sign-up")
}

useEffect(() => {
  console.log("current date==");

  // const socket = io("http://localhost:8900");
  const socket = io("https://ss.appstore.et/");

  console.log("current date==2");

  // Fetch data initially
  socket.emit("joinRoom", "Dashbord");

  console.log("current date==3");
 
  const handleOnlineStatus = (status) => {
    setCount(status);
    // Save the count to localStorage
    localStorage.setItem("onlineUsersCount", status.toString());
  };
  socket.on("Listofonlineusers", handleOnlineStatus);

  const handleTotalUsers = (datas) => {
  console.log("current date==4");

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);
    const pastDate = new Date(
      currentDate.getTime() - 7 * 24 * 60 * 60 * 1000
    );
    console.log("current date==" + currentDate);
    console.log("pastDate ==" + pastDate);

    const filteredData = datas.data.filter((entry) => {
      const entryDate = new Date(entry.updatedAt);
      console.log("entry" + entry.updatedAt);
      return entryDate >= pastDate && entryDate <= currentDate;
    });
    console.log("filteredData ==", filteredData.length);

    const uniquePhoneNumbers = new Set(
      filteredData.map((entry) => entry.phoneNumber)
    );
    const count = uniquePhoneNumbers.size;
    setweeklyonline(datas.data.length + 35);
    setweeklyonline2(count);
  };

  socket.on("Totaluserdata", handleTotalUsers);


  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  const DailyUserCount = (datas) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight

    console.log("DailyUserCount current date == " + currentDate);

    const DailyOnlineUser = datas.data.filter((entry) => {
      const entryDate = new Date(entry.updatedAt);
      entryDate.setHours(0, 0, 0, 0); // Set time to midnight

      console.log("entryDate____" + entryDate);
      console.log("DailyOnlineUser___entry" + entry.updatedAt);

      // Compare only the year, month, and day
      return (
        entryDate.getFullYear() === currentDate.getFullYear() &&
        entryDate.getMonth() === currentDate.getMonth() &&
        entryDate.getDate() === currentDate.getDate()
      );
    });

    console.log("DailyOnlineUser ==", DailyOnlineUser.length);

    const uniquePhoneNumbers = new Set(
      DailyOnlineUser.map((entry) => entry.phoneNumber)
    );
    const dailyCount = uniquePhoneNumbers.size;
    setdaileOnlineUSers(dailyCount);
  };

  socket.on("Totaluserdata", DailyUserCount);

  const DailyUserCountStoremDb = (datas) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set time to midnight
    console.log("DailyUserCount current date == " + currentDate);
    const DailyOnlineUser = datas.data.filter((entry) => {
      const entryDate = new Date(entry.updatedAt);
      entryDate.setHours(0, 0, 0, 0); // Set time to midnight
      console.log("entryDate____" + entryDate);
      console.log("DailyOnlineUser___entry" + entry.updatedAt);
      // Compare only the year, month, and day
      return (
        entryDate.getFullYear() === currentDate.getFullYear() &&
        entryDate.getMonth() === currentDate.getMonth() &&
        entryDate.getDate() === currentDate.getDate()
      );
    });

    console.log("DailyOnlineUser ==", DailyOnlineUser.length);

    const uniquePhoneNumbers = new Set(
      DailyOnlineUser.map((entry) => entry.phoneNumber)
    );
    const dailyCount = uniquePhoneNumbers.size;
    setdaileOnlineUSers(dailyCount);
  };

  socket.on("DailyUserCountStoremDb", DailyUserCountStoremDb);

  // DailyUserCountcallFrontend

  const interval = setInterval(() => { }, 5 * 60 * 1000); // Refresh data every 5 minutes
  setinterval(interval);
  return () => {
    clearInterval(interval);
    socket.disconnect();
  };
}, []);
  ////////////////////////////////////////////////////


  useEffect(() => {
    updateCounter();
  }, []);

  const updateCounter = () => {
    fetch('https://staging.appstore.et/api/count')
      .then(response => response.json())
      .then(data => {
        setCounter(data.count);
      })
      .catch(error => {
        console.error('Failed to fetch download counter.', error);
      });
  };

  const increaseCounter = () => {
    fetch('https://staging.appstore.et/api/increase', { method: 'POST' })
      .then(() => {
        updateCounter();
      })
      .catch(error => {
        console.error('Failed to increase download counter.', error);
      });
    };
     
  useEffect(() => {
    fetchUserCount();
  }, []);

  const fetchUserCount = () => {
    fetch('https://staging.appstore.et/api/count1')
      .then(response => response.json())
      .then(data => {
        setCountapp(data.count);
      })
      .catch(error => {
        console.error('Failed to fetch user count.', error);
      });
  };

  const increaseUserCount = () => {
    fetch('https://staging.appstore.et/api/increase1', { method: 'POST' })
      .then(() => {
        fetchUserCount();
      })
      .catch(error => {
        console.error('Failed to increase user count.', error);
      });
  };


  return (
    <div>
    {
    user ?
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "No. of download mobile app" }}
                count={counter!=0.5 ? counter : <Spinner/> }
                percentage={{ color: "success", text: "" }}
                icon={{
                  color: "info",
                  component: "people_outline",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatisticsCard
                title={{ text: "No. download Desktop app" }}
                count={countapp!=0.5 ? countapp : <Spinner/> }
                percentage={{ color: "success", text: "" }}
                icon={{
                  color: "info",
                  component: "people_outline",
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={7}>
              <BuildByDevelopers />
            </Grid>
            <Grid item xs={12} lg={5}>
              <WorkWithTheRockets />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={5}>
              <ReportsBarChart
                
              />
            </Grid>
            <Grid item xs={12} lg={7}>
              <LineGraph
              />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  :""  
  }
    </div>
  );
}

export default Adminpage;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    width: 100%;
 
 
 
 
    border-collapse: collapse;
    /* margin-top: 20px; */
  }
  
  .table th,
  .table td {
    text-align: left;
    padding: 15px;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .table button {
    background-color: #4CAF50;
    color: white;
    padding: 8px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
`, "",{"version":3,"sources":["webpack://./src/layouts/dashboard/components/BuildByDevelopers/table.css"],"names":[],"mappings":"AAAA;IACI,WAAW;;;;;IAKX,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;;IAEE,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".table {\n    width: 100%;\n \n \n \n \n    border-collapse: collapse;\n    /* margin-top: 20px; */\n  }\n  \n  .table th,\n  .table td {\n    text-align: left;\n    padding: 15px;\n  }\n  \n  .table th {\n    background-color: #f2f2f2;\n  }\n  \n  .table tbody tr {\n    border-bottom: 1px solid #ddd;\n  }\n  \n  .table tbody tr:hover {\n    background-color: #f5f5f5;\n  }\n  \n  .table button {\n    background-color: #4CAF50;\n    color: white;\n    padding: 8px 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

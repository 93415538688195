// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .profile-table th,
  .profile-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .profile-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .profile-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .profile-table tbody tr:hover {
    background-color: #f0f0f0;
  }
  
  .profile-table button {
    background-color: #bb83f3;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
  }
  
 `, "",{"version":3,"sources":["webpack://./src/examples/Charts/LineCharts/GradientLineChart/go.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,8BAA8B;IAC9B,WAAW;EACb;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,6CAA6C;EAC/C","sourcesContent":[".profile-table {\n    width: 100%;\n    border-collapse: collapse;\n    font-family: Arial, sans-serif;\n    color: #333;\n  }\n  \n  .profile-table th,\n  .profile-table td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .profile-table th {\n    background-color: #f2f2f2;\n    font-weight: bold;\n  }\n  \n  .profile-table tbody tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n  \n  .profile-table tbody tr:hover {\n    background-color: #f0f0f0;\n  }\n  \n  .profile-table button {\n    background-color: #bb83f3;\n    color: #fff;\n    border: none;\n    padding: 5px 10px;\n    cursor: pointer;\n    font-size: 14px;\n    text-transform: uppercase;\n    transition: background-color 0.3s, color 0.3s;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

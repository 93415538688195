import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import Chart, { ChartOptions, ChartData } from 'chart.js/auto';
import './go.css';
import { Hidden } from '@mui/material';

function App() {

  const [feedbackData, setFeedbackData] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const ApiKey = "a325-71b0-227a-2012"
  useEffect(() => {
    // https://staging.appstore.et
    fetch('https://staging.appstore.et/api/profiles', {
      headers: {
        'X-API-Key': ApiKey,
      },
    }

    )
      .then(response => response.json())
      .then(data => {
        setProfiles(data);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    fetch('https://staging.appstore.et/api/feedback')
      .then(response => response.json())
      .then(data => setFeedbackData(data))
      .catch(error => console.log(error));
  }, []);


  const handleCardClick = feedback => {
    setSelectedFeedback(feedback);
  };

  const handleCloseModal = () => {
    setSelectedFeedback(null);
  };
  const getElapsedTime = (uploadedTime) => {
    const currentTime = new Date();
    const elapsedMinutes = Math.floor((currentTime - new Date(uploadedTime)) / (1000 * 60));
    if (elapsedMinutes < 1) {
      return 'just now';
    } else if (elapsedMinutes === 1) {
      return '1 minute ago';
    } else if (elapsedMinutes < 60) {
      return `${elapsedMinutes} minutes ago`;
    } else if (elapsedMinutes < 1440) {
      const elapsedHours = Math.floor(elapsedMinutes / 60);
      return `${elapsedHours} hours ago`;
    } else {
      const elapsedDays = Math.floor(elapsedMinutes / 1440);
      return `${elapsedDays} days ago`;
    }
  };

  const handleDelete = (profileId) => {
    // https://staging.appstore.et
    fetch(`https://staging.appstore.et/api/profiles/${profileId}`, {
      method: "DELETE"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Profile not found");
        }
      })
      .then(data => {
        if (data.success) {
          setProfiles(prevProfiles => prevProfiles.filter(profile => profile._id !== profileId));
        } else {
          console.error(data.message);
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  };
  return (

    <div style={{}}>

      <div style={{
        backgroundColor: 'white',
        mixHeight: '20px', // Adjusted minimum height
        minWidth: '150px',
        height: '100%',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <center><p>list of uplaod </p></center>
        <div >

          <table className="profile-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Item</th>

                <th>Time</th>
                <th>By Whom</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {profiles.map(profile => (
                <tr key={profile._id}>
                  <td>{profile.name}</td>
                  <td>{profile.item}</td>
                  <td>{getElapsedTime(profile.time)}</td>
                  <td>{profile.uploader}</td>
                  <td>
                    <button onClick={() => handleDelete(profile._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{
          margin: '24px',
          backgroundColor: 'white',
          minHeight: '150px',
          height: '790px',
          padding: '10px',
          borderRadius: '10px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'auto',
        }}
      >
        <div style={{ position: 'sticky', top: '0', backgroundColor: 'white' }}>
          <center>
            <p style={{fontWeight:"bolder"}}>list of feedback</p>
          </center>
        </div>
        <div>
          <div>
            {feedbackData.map(feedback => (
              <div
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '16px',
                  margin: '8px',
                  width: '100%',
                  backgroundColor: 'white',
                  cursor: 'pointer',
                }}
                key={feedback.id}
                onClick={() => handleCardClick(feedback)}
              >
                <h2>{feedback.fullname}</h2>
                <p>{feedback.email}</p>
                <p>{feedback.phone}</p>
                <p>{feedback.message}</p>
              </div>
            ))}
          </div>
        </div>
        {selectedFeedback && (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundColor: 'white',
                padding: '100px 240px', 
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '590px',
                margin: '0 auto',
                textAlign: 'center',
              }}
            >
              <h2 style={{ fontSize: '24px', marginBottom: '16px' }}>{selectedFeedback.fullname}</h2>
              <p style={{ fontSize: '16px', marginBottom: '8px' }}>{selectedFeedback.email}</p>
              <p style={{ fontSize: '16px', marginBottom: '8px' }}>{selectedFeedback.phone}</p>
              <p style={{ fontSize: '16px', marginBottom: '24px' }}>{selectedFeedback.message}</p>
              <p style={{ fontSize: '16px', marginBottom: '24px' }}>  {new Date(selectedFeedback.createdAt).toLocaleString()}</p>

              {/* Add additional fields based on your feedback data structure */}
              <br></br>
            

              <button
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  padding: '10px 32px',
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>

  );

}

export default App;


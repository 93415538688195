import React, { useState, useEffect, useRef } from "react";
import Chart from "chart.js/auto";

function App() {
  const [count, setCount] = useState(0);
  const [data, setData] = useState({});
  const [searchDate, setSearchDate] = useState("");
  const chartRef = useRef(null);
  const [weeklyonline, setWeeklyOnline] = useState(0);

  const handleSearch = (event) => {
    setSearchDate(event.target.value);
  };

  const filteredEntries = data.weeklyOnlineUsers
    ? data.weeklyOnlineUsers.filter((entry) => {
      const entryDate = new Date(entry.date).toLocaleString();
      return entryDate.includes(searchDate);
    })
    : [];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://ss.appstore.et/lastseen/users//Total-of-users-table'
        );
        const { updateTime, numberOfUsers, weeklyOnlineUsers } =
          await response.json();

        setData({ updateTime, numberOfUsers, weeklyOnlineUsers });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  // Render the chart
  useEffect(() => {
    if (chartRef.current && data.weeklyOnlineUsers) {
      const ctx = chartRef.current.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: {
          labels: data.weeklyOnlineUsers.map((entry) =>
            new Date(entry.date).toLocaleString()
          ),
          datasets: [
            {
              label: "Number of Online Users",
              data: data.weeklyOnlineUsers.map((entry) => entry.userCount),
              fill: false,
              borderColor: "rgba(75,192,192,1)",
              tension: 0.1,
            },
          ],
        },
        options: {
          // Add any chart options here
        },
      });
    }
  }, [data]);

  return (
    <div className="Tableandlinegraph" style={{
      backgroundColor: 'white',
      minHeight: '150px', // Adjusted minimum height
      minWidth: '350px',
      height: '100%',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    }}>
      <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
      <br />
      <div className="shortdisc">
        <div className="noofusers3">
          <input
            style={{
              width: '300px',
              padding: '10px',
              border: '2px solid #3498db',
              borderRadius: '20px',
              outline: 'none',
              fontSize: '16px',
              transition: 'border-color 0.3s',
            }}
            type="text"
            value={searchDate}
            onChange={handleSearch}
            placeholder="Search by date and time"
          />
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            <table className="data-table" style={{
              backgroundColor: 'white',
              width: '100%',
              padding: '10px',
              borderCollapse: 'collapse',
            }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'left', padding: '10px' }}>Date</th>
                  <th style={{ textAlign: 'center', padding: '10px' }}>Online Users</th>
                </tr>
              </thead>

              <tbody>
                {filteredEntries.map((entry) => (
                  <tr key={entry._id} style={{
                    borderBottom: '1px solid grey',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    transition: 'background-color 0.3s',
                  }} className="table-row-hover">
                    <td style={{ textAlign: 'left', padding: '10px' }}>{new Date(entry.date).toLocaleString()}</td>
                    <td style={{ textAlign: 'center', padding: '10px' }}>{entry.numberOfOnline}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <canvas ref={chartRef}></canvas> */}
      </div>
    </div>
  );
}

export default App;

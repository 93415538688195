import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import Chart, { ChartOptions, ChartData } from 'chart.js/auto';
import { format } from 'date-fns';
  
function LineGraphAll() {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Number of Online Users',
        data: [],
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 2,
        fill: false,
      },
    ],
  });
  const [datas, setData] = useState({});
  const [weeklyonline2, setweeklyonline2] = useState(0);
  const [fromDate, setFromDate] = useState(0);
  const [toDate, setToDate] = useState(0);
  const chartRef = useRef(null);

  useEffect(() => {
    const socket = io('https://ss.appstore.et/');

    // Fetch data initially
    socket.emit('joinRoom', 'Dashbord');
    socket.emit('joinRoom', 'room', { fromDate, toDate });

    const handleTotalUsers = (datas) => {
      setData(datas);
    };

    socket.on('Totaluserdata', handleTotalUsers);

    const handleTotalUsers2 = (data) => {
      if (data.data) {
        const labels = data.data.map((entry) =>
        format(new Date(entry.date), "MM/dd/yyyy")
      );
        const onlineUsers = data.data.map((entry) => entry.numberOfOnline);
        setChartData({
          labels,
          datasets: [
            {
              ...chartData.datasets[0],
              data: onlineUsers,
            },
          ],
        });
      } else {
        setChartData({
          labels: [],
          datasets: [
            {
              ...chartData.datasets[0],
              data: [],
            },
          ],
        });
      }
    };

    socket.on("ListofonlineusersDataforBarGraph2", handleTotalUsers2);

    const interval = setInterval(() => {
      // Refresh data every 5 minutes
    }, 5 * 60 * 1000);

    return () => {
      clearInterval(interval);
      socket.disconnect();
    };
  }, [fromDate, toDate]);

  const handleDateSubmit = (e) => {
    e.preventDefault();

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setHours(0, 0, 0, 0);

    const filteredData = datas.data.filter((entry) => {
      const entryDate = new Date(entry.updatedAt);
      return (
        entryDate >= new Date(fromDate) && entryDate <= new Date(toDate)
      );
    });

    const uniquePhoneNumbers = new Set(
      filteredData.map((entry) => entry.phoneNumber)
    );
    const counts = uniquePhoneNumbers.size;
    setweeklyonline2(counts);
  };

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        // Destroy the existing chart
        Chart.getChart(ctx)?.destroy();
      }
    }

    const ctx = chartRef.current;
    const lineChart = new Chart(ctx, {
      type: 'line',
      data: chartData,
      options: {
        responsive: true,
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            beginAtZero: true,
          },
        },
      },
    });

    return () => {
      lineChart.destroy();
    };
  }, [chartData]);

  return (
    <div>

     

      <div>
        <canvas ref={chartRef}></canvas>
      </div>
    </div>
  );
}

export default LineGraphAll;

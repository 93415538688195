
import Dashboard from "layouts/dashboard";
import Adminpage from "layouts/Adminpage/Adminpage";

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";

import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import Tables from "layouts/tables";
import Office from "examples/Icons/Office";
import Billing from "layouts/billing";
import CreditCard from "examples/Icons/CreditCard";
import VirtualReality from "layouts/virtual-reality";
import Cube from "examples/Icons/Cube";
import CustomerSupport from "examples/Icons/CustomerSupport";
import Profile from "layouts/profile";




const routesAdmins = [
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <Shop size="12px" />,
      component: <Dashboard />,
      noCollapse: true,
    },
    // ... (other route objects)
    {
        type: "collapse",
        name: "Analytics",
        key: "analytics",
        route: "/analytics",
        icon: <Office size="12px" />,
        component: <Tables />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "User Managment",
        key: "userManagment",
        route: "/userManagment",
        icon: <CreditCard size="12px" />,
        component: <Billing />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Admin",
        key: "virtual-reality",
        route: "/authentication/sign-in",
        icon: <Cube size="12px" />,
        component: <SignIn />,
        noCollapse: true,
      },
      {
        type: "collapse",
        name: "Change Password",
        key: "profile",
        route: "/profile",
        icon: <CustomerSupport size="12px" />,
        component: <Profile />,
        noCollapse: true,
      },
  
    {
      route: "/authentication/sign-up",
      icon: <SpaceShip size="12px" />,
      component: <SignUp />,
    },
  ];
  
  export default routesAdmins;
/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { useState, useEffect } from "react";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useSignup } from "hooks/useSignup";
// Images
import ivancik from "assets/images/ivancik.jpg";
import "./form.css";
function WorkWithTheRockets() {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isLoading } = useSignup();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [username, setIsUsername] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
    } else {
      setIsSubmitted(true);
      const userData = await signup(email, password, username, role);

      // Handle the user data as needed
      console.log(userData);
    }
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox position="relative" height="100%" p={2}>
        <SoftBox>
          <SoftTypography
            component="a"
            // href="#"
            // variant="button"
            // color="black"
            // fontWeight="medium"
            // sx={{
            //   mt: "auto",
            //   mr: "auto",
            //   display: "inline-flex",
            //   alignItems: "center",
            // //   cursor: "pointer",

            // }}
          >
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="form-group">
                      <label>User Name</label>
                      <input
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setIsUsername(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <div className="pass-group">
                        <input
                          type="password"
                          name="password"
                          className="pass-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="col-lg-3 col-sm-6 col-12">
                      <label>Role</label>
                      <select
                        className="select"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option>Select</option>
                        <option value="admin">admin</option>
                        <option value="user">user</option>
                      </select>
                    </div>

                    <br />
                    <div className="form-group" style={{ marginTop: "12px" }}>
                      <label>Confirm Password</label>
                      <div className="pass-group">
                        <input
                          type="password"
                          name="confirmPassword"
                          className="pass-inputs"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    {error && <div className="error">{error}</div>}
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    {isSubmitted && !error && (
                      <div className="success">Account created successfully!</div>
                    )}
                  </div>

                  <div className="col-lg-12">
                    <center>
                      <button type="submit" className="btn btn-submit me-2">
                        Sign up
                      </button>
                      <button type="button" className="btn btn-cancel">
                        Cancel
                      </button>
                    </center>
                  </div>
                </div>
              </form>
            </div>
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default WorkWithTheRockets;
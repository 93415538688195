import { useState } from 'react';
import { useAuthContext } from './useAuthContext';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();
const ApiKey = "a325-71b0-227a-2012"
  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);
// https://staging.appstore.et
    const response = await fetch('https://staging.appstore.et/api/user/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json', 'X-API-Key': ApiKey},
      body: JSON.stringify({ email, password })
    });

    const json = await response.json();

    if (!response.ok) {
      setIsLoading(false);
      setError(json.error);
      window.location.replace("/authentication/sign-up");

    }

    if (response.ok) {
      // save the user to local storage
      localStorage.setItem('user', JSON.stringify(json));
      // https://staging.appstore.et  
      const roleResponse = await fetch('https://staging.appstore.et/api/user/role', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (roleResponse.ok) {
        const data = await roleResponse.json();
        setUserRole(data.role);
        localStorage.setItem('userRole', JSON.stringify(data.role))

        
        // Now you can use data.role instead of userRole
        window.location.replace("/dashboard");
        

        // update the auth context
        dispatch({ type: 'LOGIN', payload: json });

        // update loading state
        setIsLoading(false);
      } else {
       
        setIsLoading(false);
      }
    }
  };

  return { login, isLoading, error };
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submit-button{
    background-color: #bb83f3;
    color: #fff;
    border: none;
    padding: 15px 19px;
    cursor: pointer;
    font-size: 14px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
  }`, "",{"version":3,"sources":["webpack://./src/examples/Charts/BarCharts/ReportsBarChart/fileform.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,yBAAyB;IACzB,6CAA6C;EAC/C","sourcesContent":[".submit-button{\n    background-color: #bb83f3;\n    color: #fff;\n    border: none;\n    padding: 15px 19px;\n    cursor: pointer;\n    font-size: 14px;\n    text-transform: uppercase;\n    transition: background-color 0.3s, color 0.3s;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import "./table.css"
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Images
import wavesWhite from "assets/images/shapes/waves-white.svg";
import rocketWhite from "assets/images/illustrations/rocket-white.png";
import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import "../../index.css"
import Spinner from "../../Preloader"


function BuildByDevelopers() {
 
    
    
    
    const [users, setUsers] = useState([]);
    
    useEffect(() => {
      // https://staging.appstore.et
    fetch("https://staging.appstore.et/api/user/users")
    .then((response) => response.json())
    .then((data) => setUsers(data))
    .catch((error) => {
     
    console.error("Error fetching user data:", error);
    });
    }, []);
    
    const handleDelete = (userId) => {
      // https://staging.appstore.et  
    fetch(`https://staging.appstore.et/api/user/users/${userId}`, {
    method: "DELETE",
    })
    .then((response) => response.json())
    .then((data) => {
    // Update the user list after successful deletion
    setUsers(users.filter((user) => user._id !== userId));
    })
    .catch((error) => {
    console.error("Error deleting user:", error);
    });
    };
  return (
    <Card>
      <SoftBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={19} lg={16}>
            <SoftBox display="flex" flexDirection="column" height="100%">
              <SoftBox pt={1} mb={0.5}>
                <SoftTypography variant="body2" color="text" fontWeight="medium">
                 List of users
                </SoftTypography>
              </SoftBox>
           
              
              <SoftTypography
                component="a"
                href="#"
                variant="button"
                color="text"
                fontWeight="medium"
                sx={{
               
                
                  display: "inline-flex",
                  alignItems: "center",
                  cursor: "pointer",

                  "& .material-icons-round": {
                    fontSize: "1.125rem",
                    transform: `translate(2px, -0.5px)`,
                    transition: "transform 0.2s cubic-bezier(0.34,1.61,0.7,1.3)",
                  },

                  "&:hover .material-icons-round, &:focus  .material-icons-round": {
                    transform: `translate(6px, -0.5px)`,
                  },
                }}
              >
          <table className="table datanew" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
  <thead>
    <tr>
      <th style={{ textAlign: 'left' }}>
      
      </th>
      {/* <th style={{ textAlign: 'left' }}>UserName</th> */}
      <th style={{ textAlign: 'left' }}>Email</th>
      <th style={{ textAlign: 'left' }}>Role</th>
      <th style={{ textAlign: 'left' }}>Status</th>
      <th style={{ textAlign: 'left' }}>Action</th>
    </tr>
  </thead>
  <tbody>
    {users.map((user) => (
      <tr key={user._id}>
        <td>
        </td>
        <td style={{ textAlign: 'left' }}>{user.email}</td>
        {/* <td style={{ textAlign: 'left' }}>{user.role}</td> */}
        <td style={{ textAlign: 'left' }}>{user.username}</td>
        
        <td style={{ textAlign: 'left' }}>
          <span className="bg-lightgreen badges">Active</span>
        </td>
        <td style={{ textAlign: 'left' }}>
          <a className="me-3">
            <button onClick={() => handleDelete(user._id)}>
              <p>Delete</p>
            </button>
          </a>
        </td>
      </tr>
    ))}
  </tbody>
</table>

         
          
              </SoftTypography>
             
            </SoftBox>
          </Grid>
         
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BuildByDevelopers;

/**
=========================================================
* Soft UI Dashboard React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";
import React, { useState } from "react";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function ProfileInfoCard({ title, description }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  // const { user } = useAuthContext();
  // const navigate = useNavigate(); // Initialize navigate function

  const user = JSON.parse(localStorage.getItem('user'));

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      setSuccess("");
      return;
    }

    const requestData = {
      email: user.email,
      currentPassword: currentPassword,
      newPassword: newPassword,
    };
    // https://staging.appstore.et
    fetch("https://staging.appstore.et/api/user/change-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setError(data.error);
          setSuccess("");
        } else {
          setSuccess(data.message);
          setError("");
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          // navigate("/"); // Redirect to the home page
          window.location.replace("/dashboard")
        }
      })
      .catch((error) => {
        setError("Error changing password. Please try again later.");
        setSuccess("");
        console.error("Error:", error);
      });
  };


  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
       <center>{title}</center>
        </SoftTypography>
       
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox mb={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            {description}
          </SoftTypography>
        </SoftBox>
        <SoftBox opacity={1}>
        <form onSubmit={handleSubmit} style={{ maxWidth: '300px', margin: 'auto' }}>
  <label htmlFor="currentPassword">Current Password:</label>
  <input
    type="password"
    id="currentPassword"
    name="currentPassword"
    value={currentPassword}
    onChange={handleCurrentPasswordChange}
    required
    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
  />
  <br />
  <br />
  <label htmlFor="newPassword">New Password:</label>
  <input
    type="password"
    id="newPassword"
    name="newPassword"
    value={newPassword}
    onChange={handleNewPasswordChange}
    required
    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
  />
  <br />
  <br />
  <label htmlFor="confirmPassword">Confirm Password:</label>
  <input
    type="password"
    id="confirmPassword"
    name="confirmPassword"
    value={confirmPassword}
    onChange={handleConfirmPasswordChange}
    required
    style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
  />
  <br />
  <br />
  <input
    type="submit"
    value="Change Password"
    style={{
      backgroundColor: '#3498db',
      color: 'white',
      padding: '10px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
    }}
  />
</form>

          <Divider />
         <center> {error && <p>{error}</p>} </center> 
      {/* {success && <p>{success}</p>} */}
        </SoftBox>
        <SoftBox>
          
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  
};

export default ProfileInfoCard;
